import React from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import { ExpandMore, Refresh } from '@mui/icons-material';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { every, filter, find, forEach, get, includes, isEmpty, isNil, map, pickBy } from 'lodash';
import type { CardProps } from '@mui/material';
import { CardLevel, LoadingButton, LoadingSpinner } from '../../../components';
import { formatTime, joinPairs, labelForEnum } from '../../../utils/libs';
import { JobHiringActionItem } from './job_hiring_action_item';
import { JobHiringActionOption, ActionValueType } from './job_hiring_action_option';
import { JobHiringsCardItem } from './job_hirings_card_item';

import type { JobHiringActionType } from './job_hiring_action_option';
import type { JobHiringType } from './job_hirings_card_item';
import { createScopedI18n } from '../../../i18n/i18n';

const jobHiringsCard2StatesI18n = createScopedI18n('components.job_hiring_card_2.states', { joinOutput: true });

export type JobHiringsCardProps = {
  jobId: string;
} & CardProps;

const filterJobHirings = (jobHirings: JobHiringType[], jobHiringAction?: JobHiringActionType | undefined | null) => {
  let filteredJobHirings: JobHiringType[];
  switch (jobHiringAction) {
    case 'startWork':
      filteredJobHirings = filter(jobHirings, (jobHiring) => isNil(jobHiring.startOperationTime));
      break;
    case 'getOffWork':
      filteredJobHirings = filter(
        jobHirings,
        (jobHiring) => !isNil(jobHiring.startOperationTime) && jobHiring.status !== 'done',
      );
      break;
    case 'review':
      filteredJobHirings = filter(
        jobHirings,
        (jobHiring) => jobHiring.status === 'done' && isNil(jobHiring.studentReview),
      );
      break;
    case 'ban':
      filteredJobHirings = filter(jobHirings, (jobHiring) => jobHiring.status === 'done');
      break;
    default:
      filteredJobHirings = jobHirings;
      break;
  }

  return filteredJobHirings;
};

export const getJobWithJobHiringsGql = gql(`
  query getJobWithJobHirings($jobId: ID!) {
    job(id: $jobId) {
      id
      jobType
      jobStartAt
      jobEndAt
      jobHirings {
        id
        status
        startOperationTime
        endOperationTime
        breakDurationMinute
        student {
          id
          avatar
          firstName
          lastName
          nickName
        }
        studentReview {
          id
          star
        }
      }
      restaurant {
        id
      }
    }
  }
`);

const startWorkMutationGql = gql(`
  mutation startWorkMutation($jobHiringId: ID!, $startOperationAt: DateTime) {
    updateJobHiringOperationTime(jobHiringId: $jobHiringId, startOperationAt: $startOperationAt) {
      success
      errors
    }
  }
`);

const getOffWorkMutationGql = gql(`
  mutation getOffWorkMutation($jobHiringId: ID!, $lateMinute: Int) {
    getOffWork(jobHiringId: $jobHiringId, lateMinute: $lateMinute) {
      success
      errors
    }
  }
`);

const reviewStudentMutationGql = gql(`
  mutation reviewStudentMutation($commends: [String], $jobHiringId: ID!, $star: Int!) {
    reviewStudent(commends: $commends, jobHiringId: $jobHiringId, star: $star) {
      success
      errors
    }
  }
`);

const banStudentMutationGql = gql(`
  mutation banStudentMutation($restaurantId: ID!, $studentId: ID!, $isBanChain: Boolean) {
    banStudent(restaurantId: $restaurantId, studentId: $studentId, isBanChain: $isBanChain) {
      success
      errors
    }
  }
`);

const absentStudentMutationGql = gql(`
  mutation absentStudentMutation($jobHiringId: ID!) {
    reportJobHiringAbsent(jobHiringId: $jobHiringId) {
      success
      errors
    }
  }
`);

export const JobHiringsCard2 = ({ jobId, sx, ...props }: JobHiringsCardProps) => {
  const {
    data: jobWithHiringsData,
    loading: jobWithHiringsLoading,
    refetch: jobWithHiringsRefetch,
  } = useQuery(getJobWithJobHiringsGql, {
    variables: { jobId },
    skip: !jobId,
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const jobHiringRefetchHandler: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    await jobWithHiringsRefetch();
  };

  const job = jobWithHiringsData?.job;
  const jobHirings = React.useMemo(
    () => jobWithHiringsData?.job?.jobHirings ?? [],
    [jobWithHiringsData?.job?.jobHirings],
  );

  const [jobHiringAction, setJobHiringAction] = React.useState<JobHiringActionType | null>(null);
  const filteredJobHirings = filterJobHirings(jobHirings, jobHiringAction);

  const [expand, setExpand] = React.useState(true);

  const [selection, setSelection] = React.useState<string[]>([]);
  const toggleSelection = (jobHiringId: string) => {
    if (includes(selection, jobHiringId)) {
      setSelection(filter(selection, (id) => id !== jobHiringId));
    } else {
      setSelection([...selection, jobHiringId]);
    }
  };
  const toggleFilteredSelectAllHandler = () => {
    if (isEmpty(selection)) {
      setSelection(map(filteredJobHirings, (jobHiring) => jobHiring.id));
    } else {
      setSelection([]);
    }
  };

  const toggleJobHiringActionHandlerHigherOrder = (nextAction: JobHiringActionType) => () => {
    setSelection([]);
    if (jobHiringAction === nextAction) {
      setJobHiringAction(null);
    } else {
      setJobHiringAction(nextAction);
    }
  };

  const [todoAction, setToDoAction] = React.useState<JobHiringActionType | null>();
  React.useEffect(() => {
    if (!isEmpty(filterJobHirings(jobHirings, 'startWork'))) {
      setToDoAction('startWork');
      setJobHiringAction('startWork');
    } else if (!isEmpty(filterJobHirings(jobHirings, 'getOffWork'))) {
      setToDoAction('getOffWork');
      setJobHiringAction('getOffWork');
    } else if (!isEmpty(filterJobHirings(jobHirings, 'review'))) {
      setToDoAction('review');
      setJobHiringAction('review');
    } else {
      setToDoAction(null);
      setJobHiringAction(null);
    }
  }, [jobHirings]);

  const emptyState = React.useMemo(() => {
    // todoAction is startWork
    //  startWork --> show startWork options
    //  getOffWork --> ยังไม่ลงเวลาเข้างาน
    //  review --> ยังไม่ลงเวลาเข้างาน
    //  ban --> ยังไม่ลงเวลาเข้างาน

    // todoAction is getOffWork
    //  startWork --> ลงเวลาเข้างานแล้ว
    //  getOffWork --> show getOffWork options
    //  review --> ยังไม่ลงเวลาออกงาน
    //  ban --> ยังไม่ลงเวลาออกงาน

    // todoAction is review
    //  startWork --> ลงเวลาเข้างานแล้ว
    //  getOffWork --> ลงเวลาออกงานแล้ว
    //  review --> show review options
    //  ban --> show ban options

    // todoAction is ban or null(done)
    //  startWork --> ลงเวลาเข้างานแล้ว
    //  getOffWork --> ลงเวลาออกงานแล้ว
    //  review --> มีการประเมินแล้ว
    //  ban --> show ban options

    if (todoAction === 'startWork') {
      return {
        startWork: null,
        getOffWork: jobHiringsCard2StatesI18n('time_entry.not_yet'),
        review: jobHiringsCard2StatesI18n('time_entry.not_yet'),
        ban: jobHiringsCard2StatesI18n('time_entry.not_yet'),
      };
    }

    if (todoAction === 'getOffWork') {
      return {
        startWork: jobHiringsCard2StatesI18n('time_entry.already'),
        getOffWork: null,
        review: jobHiringsCard2StatesI18n('time_off.not_yet'),
        ban: jobHiringsCard2StatesI18n('time_off.not_yet'),
      };
    }

    if (todoAction === 'review') {
      return {
        startWork: jobHiringsCard2StatesI18n('time_entry.already'),
        getOffWork: jobHiringsCard2StatesI18n('time_off.already'),
        review: null,
        ban: null,
      };
    }

    return {
      startWork: jobHiringsCard2StatesI18n('time_entry.already'),
      getOffWork: jobHiringsCard2StatesI18n('time_off.already'),
      review: jobHiringsCard2StatesI18n('review.already'),
      ban: null,
    };
  }, [todoAction]);

  const selectAllStatus = React.useMemo(() => {
    if (isEmpty(selection)) return 'unchecked';
    const allChecked = every(map(filteredJobHirings, (jobHiring) => includes(selection, jobHiring.id)));
    return allChecked ? 'checked' : 'indeterminate';
  }, [selection, filteredJobHirings]);

  const onJobHiringSelectClickHigherOrder = (jobHiringId: string) => () => {
    toggleSelection(jobHiringId);
  };

  const [actionValue, setActionValue] = React.useState<ActionValueType>();
  const [errorMap, setErrorMap] = React.useState<{ [jobHiringId: string]: string | null | undefined }>({});
  const errorMessageMap = pickBy(errorMap, (error) => !!error);
  const [submitting, setSubmitting] = React.useState(false);

  const [startWorkMutation] = useMutation(startWorkMutationGql);
  const onStartWorkClickHigherOrder =
    ({ jobHiringId, offsetMinute }: { jobHiringId: string; offsetMinute: number }) =>
    async (disableUpdate?: boolean): Promise<boolean> => {
      if (actionValue?.type !== 'startWork') return false;
      if (!jobHiringId || !job?.jobStartAt) return false;

      setSubmitting(true);
      setErrorMap((prev) => ({ ...prev, [jobHiringId]: null }));
      let success = false;
      try {
        const startOperationAt = dayjs(job.jobStartAt).add(offsetMinute, 'minutes');
        const jobStartAt = dayjs(job.jobStartAt);

        if (startOperationAt.isBefore(jobStartAt)) {
          setErrorMap((prev) => ({ ...prev, [jobHiringId]: jobHiringsCard2StatesI18n('time_entry.early') }));
          return false;
        }
        // TODO: CACHE Update /job_hirings cache
        const { data } = await startWorkMutation({
          variables: {
            jobHiringId,
            startOperationAt,
          },
        });
        success = data?.updateJobHiringOperationTime?.success;

        if (success) {
          if (!disableUpdate) await jobWithHiringsRefetch();
        } else {
          const errors = data?.updateJobHiringOperationTime.errors;
          setErrorMap((prev) => ({ ...prev, [jobHiringId]: joinPairs(errors) }));
        }
      } catch (error) {
        setErrorMap((prev) => ({ ...prev, [jobHiringId]: get(error, 'message') }));
      }
      setSubmitting(false);
      return success;
    };

  const [getOffWorkMutation] = useMutation(getOffWorkMutationGql);
  const onGetOffWorkClickHigherOrder =
    (jobHiringId: string) =>
    async (disableUpdate?: boolean): Promise<boolean> => {
      if (actionValue?.type !== 'getOffWork') return false;
      if (!jobHiringId) return false;

      setSubmitting(true);
      setErrorMap((prev) => ({ ...prev, [jobHiringId]: null }));
      let success = false;
      try {
        const offsetTime = dayjs().add(actionValue.offsetMinute, 'minute');
        const currentTime = dayjs();

        if (offsetTime.isBefore(currentTime)) {
          setErrorMap((prev) => ({ ...prev, [jobHiringId]: jobHiringsCard2StatesI18n('time_off.early') }));
          return false;
        }

        // TODO: CACHE Update /job_hirings cache
        const { data } = await getOffWorkMutation({
          variables: { jobHiringId, lateMinute: actionValue.offsetMinute },
        });
        success = data?.getOffWork?.success;

        if (success) {
          if (!disableUpdate) await jobWithHiringsRefetch();
        } else {
          const errors = data?.getOffWork.errors;
          setErrorMap((prev) => ({ ...prev, [jobHiringId]: joinPairs(errors) }));
        }
      } catch (error) {
        setErrorMap((prev) => ({ ...prev, [jobHiringId]: get(error, 'message') }));
      }
      setSubmitting(false);
      return success;
    };

  const [reviewStudentMutation] = useMutation(reviewStudentMutationGql);
  const onReviewStudentClickHigherOrder =
    (jobHiringId: string) =>
    async (disableUpdate?: boolean): Promise<boolean> => {
      if (actionValue?.type !== 'review') return false;
      if (!jobHiringId) return false;

      setSubmitting(true);
      setErrorMap((prev) => ({ ...prev, [jobHiringId]: null }));
      let success = false;
      try {
        // TODO: CACHE Update /job_hirings cache
        const { data } = await reviewStudentMutation({
          variables: { jobHiringId, star: actionValue.star ?? 0, commends: actionValue.comments },
        });
        success = data?.reviewStudent?.success;

        if (success) {
          if (!disableUpdate) await jobWithHiringsRefetch();
        } else {
          const errors = data?.reviewStudent.errors;
          setErrorMap((prev) => ({ ...prev, [jobHiringId]: joinPairs(errors) }));
        }
      } catch (error) {
        setErrorMap((prev) => ({ ...prev, [jobHiringId]: get(error, 'message') }));
      }
      setSubmitting(false);
      return success;
    };

  const [banStudentMutation] = useMutation(banStudentMutationGql);
  const onBanStudentClickHigherOrder =
    (jobHiringId: string) =>
    async (disableUpdate?: boolean): Promise<boolean> => {
      if (actionValue?.type !== 'ban') return false;
      if (!jobHiringId || !job?.restaurant.id) return false;

      const studentId = find(jobHirings, (jobHiring) => jobHiring.id === jobHiringId)?.student.id;
      if (!studentId) return false;

      setSubmitting(true);
      setErrorMap((prev) => ({ ...prev, [jobHiringId]: null }));
      let success = false;
      try {
        // TODO: CACHE Update /job_hirings cache
        const { data } = await banStudentMutation({
          variables: { studentId, restaurantId: job.restaurant.id, isBanChain: actionValue.banScope === 'chain' },
        });
        success = data?.banStudent?.success;

        if (success) {
          if (!disableUpdate) await jobWithHiringsRefetch();
        } else {
          const errors = data?.banStudent.errors;
          setErrorMap((prev) => ({ ...prev, [jobHiringId]: joinPairs(errors) }));
        }
      } catch (error) {
        setErrorMap((prev) => ({ ...prev, [jobHiringId]: get(error, 'message') }));
      }
      setSubmitting(false);
      return success;
    };

  const [absentStudentMutation] = useMutation(absentStudentMutationGql);
  const onAbsentStudentClickHigherOrder =
    ({ jobHiringId, offsetMinute }: { jobHiringId: string; offsetMinute: 'absent' }) =>
    async (disableUpdate?: boolean): Promise<boolean> => {
      if (offsetMinute !== 'absent') return false;
      if (!jobHiringId || !job?.restaurant.id) return false;

      setSubmitting(true);
      setErrorMap((prev) => ({ ...prev, [jobHiringId]: null }));
      let success = false;
      try {
        // TODO: CACHE Update /job_hirings cache
        const { data } = await absentStudentMutation({
          variables: { jobHiringId },
        });
        success = data?.reportJobHiringAbsent?.success;

        if (success) {
          if (!disableUpdate) await jobWithHiringsRefetch();
        } else {
          const errors = data?.reportJobHiringAbsent.errors;
          setErrorMap((prev) => ({ ...prev, [jobHiringId]: joinPairs(errors) }));
        }
      } catch (error) {
        setErrorMap((prev) => ({ ...prev, [jobHiringId]: get(error, 'message') }));
      }
      setSubmitting(false);
      return success;
    };

  const onStartWorkClickHigherOrderProxy =
    (jobHiringId: string) =>
    async (disableUpdate?: boolean): Promise<boolean> => {
      if (actionValue?.type !== 'startWork') return false;
      if (actionValue.offsetMinute === 'absent')
        return onAbsentStudentClickHigherOrder({ jobHiringId, offsetMinute: 'absent' })(disableUpdate);
      return onStartWorkClickHigherOrder({ jobHiringId, offsetMinute: actionValue.offsetMinute })(disableUpdate);
    };

  const submitHandler = async () => {
    if (isNil(jobHiringAction) || isEmpty(selection)) return;

    setErrorMap({});
    setSubmitting(true);
    const successes: boolean[] = [];

    // Use for...of instead of forEach because forEach don't blocked by await and call refetch before mutations finish
    // eslint-disable-next-line no-restricted-syntax
    for (const jobHiringId of selection) {
      switch (jobHiringAction) {
        case 'startWork':
          // eslint-disable-next-line no-await-in-loop
          successes.push(await onStartWorkClickHigherOrderProxy(jobHiringId)(true));
          break;
        case 'getOffWork':
          // eslint-disable-next-line no-await-in-loop
          successes.push(await onGetOffWorkClickHigherOrder(jobHiringId)(true));
          break;
        case 'review':
          // eslint-disable-next-line no-await-in-loop
          successes.push(await onReviewStudentClickHigherOrder(jobHiringId)(true));
          break;
        case 'ban':
          // eslint-disable-next-line no-await-in-loop
          successes.push(await onBanStudentClickHigherOrder(jobHiringId)(true));
          break;
        default:
          break;
      }
    }

    if (every(successes)) {
      setJobHiringAction(null);
      setSelection([]);
    }

    await jobWithHiringsRefetch();
    setSubmitting(false);
  };

  return (
    <Card sx={{ ...sx }} {...props}>
      {jobWithHiringsLoading && <LoadingSpinner size={24} BoxProps={{ padding: 2 }} />}

      {!jobWithHiringsLoading && isEmpty(job) && (
        <CardLevel elevation={0}>
          <Typography variant="body2" textAlign="center" padding={2}>
            ไม่มีข้อมูล
          </Typography>
        </CardLevel>
      )}

      {!jobWithHiringsLoading && !isEmpty(job) && (
        <>
          <CardActionArea component="span" onClick={() => setExpand(!expand)}>
            <CardHeader
              title={labelForEnum('job_type', job.jobType)}
              titleTypographyProps={{ variant: 'body2' }}
              subheader={`${formatTime(job.jobStartAt)} - ${formatTime(job.jobEndAt)}`}
              subheaderTypographyProps={{ variant: 'body2' }}
              action={
                <Stack direction={'row'} gap={0.5}>
                  {/*
                  <IconButton size="small" onClick={() => {}}>
                    <QrCode fontSize="inherit" />
                  </IconButton>
                  <IconButton size="small" onClick={() => {}}>
                    <QrCode fontSize="inherit" />
                  </IconButton>
                  */}

                  <Collapse in={expand} orientation="horizontal">
                    <IconButton onClick={jobHiringRefetchHandler}>
                      <Refresh />
                    </IconButton>
                  </Collapse>

                  <IconButton size="small">
                    <ExpandMore
                      sx={{ transform: expand ? 'rotate(180deg)' : 'rotate(360deg)', transition: '0.2s ease' }}
                    />
                  </IconButton>
                </Stack>
              }
              sx={{ paddingBottom: 1 }}
            />
          </CardActionArea>

          <Collapse in={expand}>
            <CardContent sx={{ paddingTop: 0 }}>
              <ButtonGroup fullWidth size="small" disableElevation sx={{ paddingY: 1, height: 48 }}>
                <Button
                  variant={jobHiringAction === 'startWork' ? 'contained' : 'outlined'}
                  onClick={toggleJobHiringActionHandlerHigherOrder('startWork')}
                >
                  เข้างาน
                </Button>
                <Button
                  variant={jobHiringAction === 'getOffWork' ? 'contained' : 'outlined'}
                  onClick={toggleJobHiringActionHandlerHigherOrder('getOffWork')}
                >
                  ออกงาน
                </Button>
                <Button
                  variant={jobHiringAction === 'review' ? 'contained' : 'outlined'}
                  onClick={toggleJobHiringActionHandlerHigherOrder('review')}
                >
                  ประเมิน
                </Button>
                <Button
                  variant={jobHiringAction === 'ban' ? 'contained' : 'outlined'}
                  onClick={toggleJobHiringActionHandlerHigherOrder('ban')}
                >
                  แบน
                </Button>
              </ButtonGroup>

              <Collapse in={!!jobHiringAction && !isEmpty(filteredJobHirings)}>
                <CardLevel elevation={0} sx={{ margin: 1 }}>
                  <CardContent>
                    <JobHiringActionOption
                      action={isEmpty(filteredJobHirings) ? undefined : jobHiringAction}
                      onValueChange={setActionValue}
                      jobStartedAt={job.jobStartAt}
                      jobEndedAt={job.jobEndAt}
                    />
                  </CardContent>
                  <Divider />
                  <List disablePadding>
                    <ListItem disablePadding dense>
                      <ListItemButton onClick={toggleFilteredSelectAllHandler}>
                        <ListItemIcon>
                          <Checkbox
                            checked={selectAllStatus === 'checked'}
                            indeterminate={selectAllStatus === 'indeterminate'}
                            edge="start"
                            size="small"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={'เลือกเทมป์ทั้งหมด'}
                          primaryTypographyProps={{ variant: 'caption' }}
                          sx={{ marginLeft: -3 }}
                        />
                      </ListItemButton>
                    </ListItem>

                    {map(filteredJobHirings, (jobHiring) => (
                      <JobHiringActionItem
                        key={jobHiring.id}
                        jobHiring={jobHiring}
                        selected={includes(selection, jobHiring.id)}
                        onClick={onJobHiringSelectClickHigherOrder(jobHiring.id)}
                      />
                    ))}
                  </List>

                  <Divider />
                  <Collapse in={!isEmpty(errorMessageMap)}>
                    {map(
                      pickBy(errorMessageMap, (_error, jobHiringId) =>
                        includes(map(filteredJobHirings, 'id'), jobHiringId),
                      ),
                      (error) => (
                        <Alert severity="error" sx={{ margin: 0.5 }}>
                          {error}
                        </Alert>
                      ),
                    )}
                  </Collapse>
                  <Collapse in={!isEmpty(selection)}>
                    <Stack margin={1}>
                      <LoadingButton
                        variant="contained"
                        disabled={submitting}
                        loading={submitting}
                        onClick={submitHandler}
                      >
                        ส่ง
                      </LoadingButton>
                    </Stack>
                  </Collapse>
                </CardLevel>
              </Collapse>

              <Collapse in={isEmpty(filteredJobHirings)}>
                <CardLevel elevation={0}>
                  <Typography variant="body2" textAlign="center" padding={2}>
                    {jobHiringAction ? emptyState[jobHiringAction] : jobHiringsCard2StatesI18n('no_data')}
                  </Typography>
                </CardLevel>
              </Collapse>

              <Collapse in={!jobHiringAction && !isEmpty(filteredJobHirings)}>
                <List disablePadding>
                  {map(jobHirings, (jobHiring) => (
                    <JobHiringsCardItem
                      key={jobHiring.id}
                      jobHiring={jobHiring}
                      dense
                      divider
                      disableGutters
                      onStartWorkClick={onStartWorkClickHigherOrder(jobHiring.id)}
                      onGetOffWorkClick={onGetOffWorkClickHigherOrder(jobHiring.id)}
                      onReviewStudentClick={onReviewStudentClickHigherOrder(jobHiring.id)}
                    />
                  ))}
                </List>
              </Collapse>
            </CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
};
