import React from 'react';

import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Rating,
  Stack,
  Typography,
} from '@mui/material';
import { filter, includes, isNil, map } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { TimeValidationError } from '@mui/x-date-pickers';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
import { Schedule } from '@mui/icons-material';
import type { BoxProps } from '@mui/material';
import TimePickerWithShorthand, {
  TimePickerShorthand,
  TimePickerValue,
} from '../../../components/time_picker_with_shorthand';

export type JobHiringActionType = 'startWork' | 'getOffWork' | 'review' | 'ban';

type StartWorkValueType = {
  type: 'startWork';
  offsetMinute: number | 'absent';
};

type GetOffWorkValueType = {
  type: 'getOffWork';
  offsetMinute: number;
};

type ReviewValueType = {
  type: 'review';
  star: number | null;
  comments: string[];
};

type BanValueType = {
  type: 'ban';
  banScope: 'restaurant' | 'chain';
};

export type ActionValueType =
  | StartWorkValueType
  | GetOffWorkValueType
  | ReviewValueType
  | BanValueType
  | { type: null };

export type JobHiringActionOptionProps = {
  action?: JobHiringActionType | null;
  jobStartedAt?: Dayjs | string;
  jobEndedAt?: Dayjs | string;
  onValueChange?: (value: ActionValueType) => void;
} & BoxProps;

interface StartWorkOptionProps {
  onValueChange?: (value: StartWorkValueType) => void;
  jobStartedAt?: Dayjs | string | null;
}
type AbsentType = 'yes' | 'no';
const StartWorkOption = ({ onValueChange, jobStartedAt }: StartWorkOptionProps) => {
  const [offsetMinute, setOffsetMinute] = React.useState<TimePickerShorthand['offsetMinute']>(0);
  const [isAbsent, setIsAbsent] = React.useState<AbsentType>('no');
  const onTimePickerChanged = (datetime: TimePickerValue, context: PickerChangeHandlerContext<TimeValidationError>) => {
    if (isNil(datetime)) return;
    if (!isNil(context.validationError)) return;
    setOffsetMinute(dayjs(datetime).diff(jobStartedAt, 'minutes'));
  };

  // At the first time, we need to calls onValueChange to apply shorthand value
  React.useEffect(() => {
    if (isNil(onValueChange)) return;
    if (isAbsent === 'yes') {
      onValueChange({ type: 'startWork', offsetMinute: 'absent' });
    } else {
      onValueChange({ type: 'startWork', offsetMinute });
    }
  }, [onValueChange, offsetMinute, isAbsent]);

  return (
    <FormControl sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormLabel sx={{ textAlign: 'center' }}>เวลาเข้างาน</FormLabel>

      <RadioGroup
        value={isAbsent}
        onChange={(_, entryValue) => {
          setOffsetMinute(0);
          setIsAbsent(entryValue as AbsentType);
        }}
      >
        <FormControlLabel value="no" label="มาทำงาน" control={<Radio />} />
        <FormControlLabel value="yes" label="ไม่มาทำงาน" control={<Radio />} />
      </RadioGroup>

      {isAbsent === 'no' && (
        <>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
          <TimePickerWithShorthand
            shorthands={[
              { offsetMinute: 0, label: 'ตรงเวลา' },
              { offsetMinute: 15, label: 'สาย 15 นาที' },
              { offsetMinute: 30, label: 'สาย 30 นาที' },
            ]}
            value={dayjs(jobStartedAt)}
            onChange={onTimePickerChanged}
            minTime={jobStartedAt ? dayjs(jobStartedAt) : undefined}
            timeSteps={{ minutes: 15 }}
            minutesStep={15}
            desktopModeMediaQuery="@media (max-width: 1px)" // Force mobile Time Picker
            slotProps={{ textField: { InputProps: { endAdornment: <Schedule /> } } }}
          />
        </>
      )}
    </FormControl>
  );
};

interface GetOffWorkOptionProps {
  onValueChange?: (value: GetOffWorkValueType) => void;
  jobEndedAt?: Dayjs | string | null;
}
const GetOffWorkOption = ({ onValueChange, jobEndedAt }: GetOffWorkOptionProps) => {
  const [offsetMinute, setOffsetMinute] = React.useState<TimePickerShorthand['offsetMinute']>(0);
  const onTimePickerChanged = (datetime: TimePickerValue, context: PickerChangeHandlerContext<TimeValidationError>) => {
    if (isNil(datetime)) return;
    if (!isNil(context.validationError)) return;
    setOffsetMinute(dayjs(datetime).diff(jobEndedAt, 'minutes'));
  };

  // At the first time, we need to calls onValueChange to apply shorthand value
  React.useEffect(() => {
    if (isNil(onValueChange)) return;
    onValueChange({
      type: 'getOffWork',
      offsetMinute,
    });
  }, [onValueChange, offsetMinute]);

  return (
    <FormControl>
      <FormLabel sx={{ textAlign: 'center' }}>เวลาเลิกงาน</FormLabel>
      <TimePickerWithShorthand
        shorthands={[
          { offsetMinute: 0, label: 'ตรงเวลา' },
          { offsetMinute: 15, label: 'ต่อเวลา 15 นาที' },
          { offsetMinute: 30, label: 'ต่อเวลา 30 นาที' },
        ]}
        value={dayjs(jobEndedAt)}
        onChange={onTimePickerChanged}
        minTime={jobEndedAt ? dayjs(jobEndedAt) : undefined}
        timeSteps={{ minutes: 15 }}
        minutesStep={15}
        desktopModeMediaQuery="@media (max-width: 1px)" // Force mobile Time Picker
        slotProps={{ textField: { InputProps: { endAdornment: <Schedule /> } } }}
      />
    </FormControl>
  );
};

const ReviewOption = ({ onValueChange }: { onValueChange?: (value: ReviewValueType) => void }) => {
  const [star, setStar] = React.useState<number | null>(null);
  const [comments, setComments] = React.useState<string[]>([]);

  const starHandler = (_e: React.SyntheticEvent, value: number | null) => {
    setStar(value);
  };

  const toggleCommentsHigherOrder = (comment: string) => () => {
    let nextComments = [...comments];
    if (includes(comments, comment)) {
      nextComments = filter(comments, (prevComment) => prevComment !== comment);
    } else {
      nextComments = [...comments, comment];
    }
    setComments(nextComments);
  };

  React.useEffect(() => {
    if (isNil(onValueChange)) return;
    onValueChange({ type: 'review', star: star ?? 0, comments });
  }, [star, comments, onValueChange]);

  return (
    <Stack gap={2}>
      <Stack alignSelf="center" gap={1}>
        <Rating value={star} onChange={starHandler} />
      </Stack>
      <Stack gap={1} alignItems="center">
        <Typography variant="body2">โปรดบอกความรู้สึกการปฏิบัติงานเทมป์</Typography>
        <Stack direction="row" flexWrap="wrap" rowGap={0.5} columnGap={0.25} justifyContent="space-evenly">
          {map(['ตรงต่อเวลา', 'ขยัน ตั้งใจทำงาน', 'แต่งกายเรียบร้อย บุคลิกดี', 'พูดจาสุภาพ อัธยาศัยดี'], (comment) => (
            <Chip
              key={comment}
              size="small"
              color="primary"
              label={comment}
              variant={includes(comments, comment) ? 'filled' : 'outlined'}
              onClick={toggleCommentsHigherOrder(comment)}
              sx={{ border: '0.8px solid', borderColor: 'primary.main' }}
            />
          ))}
        </Stack>
        <Stack direction="row" flexWrap="wrap" rowGap={0.5} columnGap={0.25} justifyContent="space-evenly">
          {map(['เข้างานสาย', 'แต่งกายไม่เหมาะสม', 'ละทิ้งหน้าที่ระหว่างปฎิบัติ', 'พูดจาไม่สุภาพ'], (comment) => (
            <Chip
              key={comment}
              size="small"
              color="error"
              label={comment}
              variant={includes(comments, comment) ? 'filled' : 'outlined'}
              onClick={toggleCommentsHigherOrder(comment)}
              sx={{ border: '0.8px solid', borderColor: 'error.main' }}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const BanOption = ({ onValueChange }: { onValueChange?: (value: BanValueType) => void }) => {
  const [banScope, setBanScope] = React.useState<BanValueType['banScope']>('restaurant');
  const onBanScopeChange = (_event: React.ChangeEvent | React.MouseEvent, value: string) => {
    setBanScope(value as BanValueType['banScope']);
  };

  React.useEffect(() => {
    if (isNil(onValueChange)) return;
    onValueChange({ type: 'ban', banScope });
  }, [banScope, onValueChange]);

  return (
    <FormControl>
      <FormLabel sx={{ textAlign: 'center' }}>ไม่ต้องการรับเทมป์คนนี้</FormLabel>
      <RadioGroup value={banScope} onChange={onBanScopeChange}>
        <FormControlLabel value="restaurant" label="เข้าทำงานสาขานี้อีก" control={<Radio />} />
        <FormControlLabel value="chain" label="ทั้งสาขานี้ และสาขาอื่นๆ ด้วย" control={<Radio />} />
      </RadioGroup>
    </FormControl>
  );
};

export const JobHiringActionOption = ({
  action,
  jobStartedAt,
  jobEndedAt,
  onValueChange,
  ...prop
}: JobHiringActionOptionProps) => {
  React.useEffect(() => {
    if (isNil(action) && onValueChange) onValueChange({ type: null });
  }, [action, onValueChange]);

  return (
    <Box {...prop}>
      {action === 'startWork' && <StartWorkOption onValueChange={onValueChange} jobStartedAt={jobStartedAt} />}
      {action === 'getOffWork' && <GetOffWorkOption onValueChange={onValueChange} jobEndedAt={jobEndedAt} />}
      {action === 'review' && <ReviewOption onValueChange={onValueChange} />}
      {action === 'ban' && <BanOption onValueChange={onValueChange} />}
    </Box>
  );
};
